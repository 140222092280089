<template>
  <div class="">
    <el-card class="box-card" style="margin-bottom: 130px">
      <div v-html="data"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      data: "",
    };
  },
  created() {
    this.$api.agreement("get").then((res) => {
      this.list = res.data;
      res.data.forEach((element) => {
        element.list.forEach((e) => {
          if (e.agreementType.itemValue == this.$route.query.id) {
            this.data = e.content;
          }
        });
      });
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-card__body {
  padding: 30px 30px 80px 30px;
}

.box-card {
  div {
    color: #111111;
  }
}
</style>
